<template>
  <PoliciesCard />
</template>

<script>
import PoliciesCard from '@/components/PoliciesCard';

export default {
  components: {
    PoliciesCard
  }
};
</script>
