var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4"},[_c('v-card-title',[_vm._v("Policies")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.policies,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.createPolicy()}}},[_vm._v("Create Policy")])],1)]},proxy:true},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[(header.tooltip)?_c('span',[_vm._v(_vm._s(header.tooltip))]):_vm._e()])]}}}),{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocalTime(item.createdAt))+" ")]}},{key:"item.datasetAccess",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"multiple":"","column":"","active-class":"primary--text"}},_vm._l((item.datasetAccess),function(tag){return _c('v-chip',{key:tag},[_vm._v(" "+_vm._s(tag)+" ")])}),1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.presentDeleteDialog(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]),(
          _vm.config.marketplaceIntegrationEnabled === true &&
            item.marketplace &&
            item.marketplace.solutionId &&
            item.marketplace.planId
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.navigateToMarketplace(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.marketplace)+" ")])]}}],null,true)},[_c('span',[_vm._v("Marketplace Page")])]):_vm._e()]}}],null,true)}),(_vm.showDialog)?_c('Dialog',{attrs:{"title":_vm.deleteDialogTitle,"text":_vm.deleteDialogText,"confirmButtonColor":"red darken-1","confirmButtonText":"Delete"},on:{"confirmed":function($event){return _vm.deletePolicy(_vm.selectedItem)}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},model:{value:(_vm.showCreatePolicy),callback:function ($$v) {_vm.showCreatePolicy=$$v},expression:"showCreatePolicy"}},[_c('EditPolicy',{key:_vm.componentKey,attrs:{"policyData":_vm.selectedItem},on:{"close":function($event){return _vm.editCompleted(true)},"cancel":function($event){return _vm.editCompleted(false)}}})],1),_c('v-card-text',{staticStyle:{"height":"100px","position":"relative"}},[_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"pink","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":function($event){return _vm.createPolicy()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.plus))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }